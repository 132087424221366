// import external dependencies
import jquery from 'jquery';
global.$ = global.jQuery = jquery;

import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/offcanvas';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/collapse';

import './modules/hamburger';
import './modules/navbar';
import './modules/fancybox';
import './modules/youtube-iframe';
import './modules/sliders';
import './modules/sticky-header';
import './modules/mapbox';
import './modules/filters';
