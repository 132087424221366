// enable click on dropdow menu link and keep accessibility

var navDropdowns = document.querySelectorAll('.navbar-nav .dropdown-toggle');

function a11yNavMobile() {
  navDropdowns.forEach(element => {
    element.addEventListener('click', function () {
      if(this.getAttribute('aria-expanded') == 'false') {
        window.location.href = this.href;
      }
    });
  });
}

function a11yNavDesk() {
  navDropdowns.forEach(element => {
    element.addEventListener('click', function () {
      window.location.href = this.href;
    });

    element.addEventListener('mouseover', function () {
      this.classList.add('show');
      this.setAttribute('aria-expanded', 'true');

      this.addEventListener('mouseout', function () {
        this.classList.remove('show');
        this.setAttribute('aria-expanded', 'false');
      });
    });
  });
}

const mediaQuery = window.matchMedia('(max-width: 991px)');
mediaQuery.onchange = () => {
  if( mediaQuery.matches ) {
    a11yNavMobile();
  } else {
    a11yNavDesk();
  }
};

mediaQuery.onchange();
