/* eslint-disable no-unused-vars */
import Swiper from 'swiper/bundle';

/* Slider programs */
var programs_swiper = new Swiper(".programs-swiper", {
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

/* Slider news */
var news_swiper = new Swiper(".news-swiper", {
  slidesPerView: 'auto',
  spaceBetween: 0,
  loop: true,
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
  pagination: {
    el: '.swiper-pagination',
    type: 'bullets',
    clickable: true,
  },
  breakpoints: {
    768: {
      spaceBetween: 20,
      slidesPerView: 2,
    },
    992: {
      slidesPerView: 3,
      spaceBetween: 30,
    },
  },
});

/* Slider blocs carousel */
var carousel_swiper = $('.carousel-swiper');
carousel_swiper.each( (index, carousel) => {
  var carousel_id = carousel.getAttribute('id');

  new Swiper('#'+carousel_id, {
    slidesPerView: 'auto',
    spaceBetween: 20,
    loop: true,
    navigation: {
      nextEl: '.next-'+carousel_id ,
      prevEl: '.prev-'+carousel_id,
    },
    pagination: {
      el: '.pagination-'+carousel_id,
      type: 'bullets',
      clickable: true,
    },
    breakpoints: {
      768: {
        spaceBetween: 20,
        slidesPerView: 2,
      },
      992: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    },
  });
});

/* eslint-enable no-unused-vars */