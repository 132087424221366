$(document).ready(function() {
  $(document).on('click', '.page-header-form .btn-check', (e) => {
    const selects = $('.page-header-form .form-select');
    selects.each(function() {
      if ($(this).hasClass('location-'+e.target.id)) {
        $(this).removeClass('d-none').addClass('d-block');
      } else {
        $(this).removeClass('d-block').addClass('d-none');
      }
    });
  });
});
